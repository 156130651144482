<template>
  <li class="company" :id="company.id">
    <div class="company-logo">
      <img
          v-if="companiesCannotGetIcons.indexOf(company.name) === -1"
          :alt="company.name"
          :src="getIconSRC(company.name)"
          class="round-image"
      />
      <img
        v-else-if='getIconPath(company.name)'
        :alt="company.name"
        class="round-image"
        :src="getIconPath(company.name)"
      />
      <img
        v-else
        :alt="company.name"
        class="round-image"
        src="@/images/default-company-logo.png"
      />
    </div>
    <h3 class="company-name">{{ company.name }}</h3>
  </li>
</template>

<script>
import { companiesCannotGetIcons, getIconPath, getIconSRC} from '@/composables/WishList/getCompanyIcons'

export default {
  props: ["company"],
  async setup(props) {

    return {
      companiesCannotGetIcons,
      getIconPath,
      getIconSRC
    };
  },
};
</script>

<style>
.company {
  display: flex;
  padding: 1vw;
  border-top: 1px solid #808080;
}

.company:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.company:active {
  background-color: #e2e2e2;
  cursor: pointer;
}

.company-logo {
  position: relative;
  flex: 0 0 3vw;
  padding: auto;
  padding-right: 1vw;
}

.company-name {
  flex: 1 1 auto;
  align-self: center;
  font-weight: 400;
}
</style>
